import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion Leer Rotas
const leerDivisionTerritorial = async () => {
  try {
    const result = await axios.get(`${urlApi}/apideliveryns/cloud/v1/system/listDivisionTerritorial`, 
    { headers: authHeader()}
    );
    return { result: result };
  } catch (error) {
    if (error.response) {
      //localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const listarModulosBasico = async () => {
  try {
    const result = await axios.get(`${urlApi}/apideliveryns/cloud/v1/system/listModulesBasic`, 
    { headers: authHeader()}
    );
    return {  result };
  } catch (error) {
    if (error.response) {
      //localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const crearTipificacion = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/typificationsCreate`, 
    body,{ headers: authHeader()}
    );
    return  result ;
  } catch (error) {
    if (error.response) {
      //localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const leerTipificacion = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/typificationsRead`, 
    body,{ headers: authHeader()}
    );
    return  result;
  } catch (error) {
    if (error.response) {
      //localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

const editarTipificacion = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/system/typificationsUpdate`, 
    body,{ headers: authHeader()}
    );
    return  result;
  } catch (error) {
    if (error.response) {
      //localStorage.clear(); // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};
export { leerDivisionTerritorial, listarModulosBasico,crearTipificacion,
  leerTipificacion, editarTipificacion
 };
