<script>
// import {
//     MoreHorizontalIcon
//   } from "@zhuowenli/vue-feather-icons";

import "@vueform/multiselect/themes/default.css";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import Chronometer from "../../components/others/chronometer";
import { leerViajes } from "@/api/travels";
import { leerTipificacion } from "@/api/system";
import { leerConfiguracion } from "@/api/configuration";
import myLoader from "@/components/myLoader.vue";
import Swal from "sweetalert2";
import store from "@/state/store";
 import phoneDirectory from './phoneDirectory.vue';
import {
  plateMethods,
} from "@/state/helpers";

export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      isLoader:false,
      configurationData:{},
      miFecha:new Date,
      title: "Viajes",
      items: [
        {
          text: "Viajes",
          href: "/",
        },
        {
          text: "Viajes",
          active: true,
        },
      ], 
      columns: [
        {
          label: 'Placa/Cliente',
          field: 'Placa',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Nombre del Cliente', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Producto',
          field: 'product',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Producto', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Ped-Cliente',
          field: 'customerOrder',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Ped-cliente', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },

        },
        {
          label: 'Origen',
          field: 'loadCity',
           filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Origen', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        }
        ,
        {
          label: 'Destino',
          field: 'unloadCity', 
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Destino', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Carga',
          field: 'loadSite',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Carga', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Descarga',
          field: 'unloadSite',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Descarga', // placeholder for filter input
            trigger: '', //only trigger on enter not on keyup 
          },
        },
        
        
        
      ],
      rows: [],
      rowsFiltered: [],
      filterPlate:'', 
    };
  },
  components: {
    Layout,
    PageHeader,
    VueGoodTable, 
    Chronometer,
    phoneDirectory,
    myLoader,

  },
    
  watch:{
    // '$route.params': {
    //   handler: function(plate) {
    //     console.log(plate)
    //   },
    //   deep: true,
    //   immediate: true
    // }  
  },

  methods: {

    ...plateMethods,

    filteredData() {
      this.filterPlate = this.filterPlate.toUpperCase();
      this.rowsFiltered=this.rows.filter(item => {
        return item.label.includes(this.filterPlate) 
      })
    },
    mgntMessage(plate) {
      store.dispatch("plate/updatePlate", plate);
      this.$router.push({
        path: '/Mod_NS_Travel/ns_FollowUpTravel', 
        query: {
          plate: plate
        }
      });
    }, 
    async readTravels() {
      this.isLoader=true
      let body={
        "page":0,
        "filter":[{
            "campo":"ns_delivery.status",
            "operador":"NOT IN",
            "valor":['ANULADA']
        },{
            "campo":"v.status",
            "operador":"=",
            "valor":['ASIGNADO']
        }]
      };
      const result = await leerViajes(body);
      this.isLoader=false;

      if (result.data.code==200){
        var plate='';
        var reportDate=null;
        var children=[];
        result.data.data.data.map((item) => {
          if(plate!=item.plate) {
            if(plate!='') {
              this.rows.push(
                {
                  'mode':'',
                  'label':plate,
                  'html': true,
                  'reportDate':reportDate,
                  'children':children,
                } 
              )
            } 
            children=[];
            reportDate=item.reportDate;
            plate=item.plate 
          }
          children.push({
            customerName:item.cliente.CustomerName,
            product:item.product,
            customerOrder:item.customerOrder,
            loadCity:item.loadCity,
            unloadCity:item.unloadCity,
            loadSite:item.loadSite,
            unloadSite:item.unloadSite,
            Placa:item.cliente.CustomerName,
            plate:item.plate,
           
          });
        });
        // Ultimo grupo 
        this.rows.push(
          {
            'mode':'',
            'label':plate,
            'html': true,
            'reportDate':reportDate,
            'children':children
          } 
        );
        this.rowsFiltered=this.rows;
    
      }else{  
        Swal.fire("Leyendo Viajes!", result.data.data[0], "error");
      }    
    },
    async read() {
      this.isLoader=true
      let body={RowId:1}
      const result = await leerTipificacion(body);
      this.isLoader=false;
      if (result.data.code==200){
        this.module=result.data.data[0].Module;
        this.Name=result.data.data[0].Name;
        this.Description=result.data.data[0].Description;
      }else{  
        Swal.fire("Leyendo Lista Especial!", result.data.data[0], "error");
      }    
    },

    async readConfiguration() {
      this.isLoader=true
      const result = await leerConfiguracion({id:1});

      this.isLoader=false;
      if (result.data.code==200){
        this.configurationData=result.data.data[0];
      }else{  
        Swal.fire("Leyendo Configuracion!", result.message, "error");
      }    
    },
   
    formattedDateTime(data) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      };
      return data.toLocaleString('es-ES', options);
    },  
  },  
  computed: {    
  },
  mounted() {
    this.readConfiguration();
    this.readTravels();
  } 
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-xxl-12 col-sm-24 project-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-left">
              <div class="col-xl-2 col-sm-4 d-flex align-items-left"> 
                <label for="filterPlate" class="form-label px-2 mt-2 "
                  >Vehículo</label>
              
                <input
                  type="text"
                  class="form-control"
                  id="filterPlate"
                  placeholder="PLACA"
                  v-model="filterPlate"
                  @keyup="filteredData"
                />
              </div>
              <div class="col-xl-2 col-sm-4 d-flex align-items-left px-2"> 
                <div class="flex-shrink-0 ml-2">
                  <div data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" title="Directorio Teléfonico">
                    <!-- Button trigger modal directorio telefónico -->
                    <button type="button" class="btn btn-success  px-2"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal">
                      <i class="ri-cellphone-fill align-bottom"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row"> 
      <vue-good-table
        :columns="columns"
        :rows="rowsFiltered"
        :group-options="{
          enabled: true
        }"
        styleClass="vgt-table striped bordered"
        theme="black-rhino"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          nextLabel: 'Sig.',
          prevLabel: 'Ant.',
          rowsPerPageLabel: 'Reg. Por Pág',
          ofLabel: 'de',
          pageLabel: 'pág', // for 'pages' mode
          allLabel: 'todos',
        }">
      >
        <template #table-row="props">
          <span v-if="props.column.field == 'Placa'">
            {{props.row.customerName}}
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template> 
        <template #table-header-row="props">  
          <span v-if="props.column.field == 'Placa'" class="d-flex align-items-center">
            <button @click="mgntMessage( props.row.label)">
              <i class='px-2 ri-truck-fill text-danger'></i>
              <span class='text-info'>{{ props.row.label }}
              </span>
            </button> 
            <Chronometer  class='px-2 mt-3'
              :startTime="props.row.reportDate"
              :configuration="configurationData"
            />
          </span>
          
        </template> 
       
      </vue-good-table>
    </div>
    <!-- end row -->
    <phoneDirectory/>
  </Layout>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
</template>
