import axios from "axios";
import { authHeader } from "../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;

 
//Funcion Leer codigo siguiente del PUNTO
const leerConfiguracion = async (body) => {
  try {                                                                  
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Configuration/leer`, 
    body,{ headers: authHeader()}
    );
    return result ;
  } catch (error) {
    if (error.response) {
      // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.message, data:error.response }; // => the response payload
    }
  }
};


export { leerConfiguracion};
