<script>
  import 'vue-good-table-next/dist/vue-good-table-next.css';
  import { VueGoodTable   } from 'vue-good-table-next';
  import { leerDirectorio } from "@/api/drivers";
  import Swal from "sweetalert2";

  export default {
    data() {
      return {
        columns: [
          {
            label: 'Teléfono_1',
            field: 'Phone1',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Teléfono_1', // placeholder for filter input
              trigger: '', //only trigger on enter not on keyup 
            },
          },
          {
            label: 'Teléfono_2',
            field: 'Phone2',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Teléfono_2', // placeholder for filter input
              trigger: '', //only trigger on enter not on keyup 
            },
          },
          {
            label: 'Nombre1',
            field: 'Name1',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Nombre1', // placeholder for filter input
              trigger: '', //only trigger on enter not on keyup 
            },
          },
          {
            label: 'Nombre2',
            field: 'Name2',
          },
          {
            label: 'Apellido1',
            field: 'LastName1',
            filterOptions: {
              styleClass: 'class1', // class to be added to the parent th element
              enabled: true, // enable filter for this column
              placeholder: 'Apellido1', // placeholder for filter input
              trigger: '', //only trigger on enter not on keyup 
            },
          },
          {
            label: 'Apellido2',
            field: 'LastName2',
          },
        ],
        rows: [],
      }; 
    },  
    components: {
      VueGoodTable,
    },  
    mounted() {
      this.readDirectory();
    },
    methods: {
      async readDirectory() {
        this.isLoader=true
        const result = await leerDirectorio();
        this.isLoader=false;
        if (result.data.code==200){
          this.rows=result.data.data;
        }else{  
          Swal.fire("Leyendo Directorio!", result.message, "error");
        }    
      },
    }

  }  
</script>


<!-- Default Modals -->
<template>
   
  <div id="myModal" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg drawer-dialog-right">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Directorio Telefónico de Conductores</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <vue-good-table
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table striped bordered"
          
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            nextLabel: 'Sig.',
            prevLabel: 'Ant.',
            rowsPerPageLabel: 'Reg. Por Pág',
            ofLabel: 'de',
            pageLabel: 'pág', // for 'pages' mode
            allLabel: 'todos',
          }"
          />
        </div>
        
    
        <div class="modal-footer">
          <button type="button" id="btnCloseModal" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoader" style="position:absolute; top: 35%; 
    right:10%;
    z-index: 9999;  "  >
    <myLoader /> 
</div>
 
</template>


