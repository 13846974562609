<template>
  <div :class='miClass'>
    <p> {{ tiempoTranscurrido }}</p>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tiempoTranscurrido: '',
      miClass:'',
    };
  },
  created() {
    this.updateTimer();
  },
  props: {
    startTime: {
      type: String,
      default: new Date()
    },
    configuration: {
      type: Object,
    }
  },

  methods: {
    updateTimer() {

      setInterval(() => { 
        const startTime = new Date( this.startTime)
        const currentTime = new Date();
        const diferenciaEnMilisegundos =  ( startTime-currentTime );

        var segundos = Math.floor((Math.abs(diferenciaEnMilisegundos) / 1000) % 60);
        var minutos = Math.floor((Math.abs(diferenciaEnMilisegundos) / (1000 * 60)) % 60);
        var horas = Math.floor((Math.abs(diferenciaEnMilisegundos)/ (1000 *60 *60)) );
        var signo=diferenciaEnMilisegundos<0?'-':'';
              
        this.tiempoTranscurrido = signo+' '+ horas +':' +Math.abs(minutos) +':'+Math.abs(segundos);
     
        switch (true) {
          case diferenciaEnMilisegundos > 0 
               && diferenciaEnMilisegundos<this.configuration.alert1Time:
            this.miClass='px-2 text-warning';
            break;
          case diferenciaEnMilisegundos<0
              && diferenciaEnMilisegundos>this.configuration.alert2Time:
            this.miClass='px-2 text-danger';
            break;
          case diferenciaEnMilisegundos<0
              && diferenciaEnMilisegundos>this.configuration.alert3Time:
            this.miClass='px-2 text-danger';
            break;  
          default:
            this.miClass='px-2 text-success';
        }    
      }, 1000);
    },
  },
};
</script>
